import {getState} from './defaults';
//import {fileName} from './utility';

function updateImage($img, x) {
  $img.css('background-image', `url(${x})`);
}

function processData(e) {
  let $target = e.target ? $(e.target) : $(e);
  let $parent = $target.parents('.drag-drop');
  //let str = fileName($target.val());
  let img = new Image();
  
  if (e.target) {
    let files = e.target.files; // FileList object
    let file = files[0];
    
    if(file.type.match('image.*')) {
      let reader = new FileReader();
      // Read in the image file as a data URL.
      reader.readAsDataURL(file);
      reader.onload = function(e) { 
        if( e.target.readyState == FileReader.DONE) {
          img.onload = function () {
            updateImage($parent, this.src);
          };
            
          img.src = e.target.result;
          $parent.find('.value').html('');
        }
      };
    } else {
      $parent.find('.value').html('Csak kép tölthető fel!');
    }
  } else {
    if ($parent.data('preview')) {
      updateImage($parent, $parent.data('preview'));
    }
  }
}

function fileUploadImagePreview() {
  let state = getState();
  let selector = '.drag-drop input[type=file]';
  
  state.$body.on('change', selector, function (e) {
    processData(e);
  });
  
  state.$body.find(selector).each(function (i, el) {
    processData(el);
  });
}

export default fileUploadImagePreview;

import defaults, { setState, getState } from './defaults';

function q(a, b) {
  let result = [];
  let makeArray = x => Array.prototype.slice.call(x);
  if (b) {
    
    let temp = typeof a === 'string' ? document.querySelectorAll(a) : [a];
    temp = makeArray(temp);
    if (temp.length) {
      temp.forEach(x => {
        let temp2 = x.querySelectorAll(b);
        if (temp2.length){
          result = result.concat(makeArray(temp2));
        }
      });
    }
  } else {
    result = makeArray(document.querySelectorAll(a));
  }
  return result;
}

function isIE() {
  let state = getState();
  if (navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > 0) {
    state.$body.addClass('ie');
  }
}

function equalHeight(items) {
  let layout = ['xs','sm','md','lg','xl'];
  if (typeof items === 'string') {
    items = q(items);
  }
  
  layout = items[0].hasAttribute('data-equal-height-layout') 
    ? items[0].getAttribute('data-equal-height-layout').split('-') 
    : layout;
  
  let getMaxHeight = (items) => {
    return Math.max.apply(null, items.map(function(x) {
      return x.getBoundingClientRect().height;
    }));
  };
  
  let calc = function () {
    window.setTimeout(() => {
      let state = getState();
      
      items.forEach(x => x.style.height = 'auto');
      let max  = getMaxHeight(items);
  
      if (layout.indexOf(state.layout) === -1) {
        items.forEach(x => x.style.height = 'auto');
      } else {
        items.forEach(x => x.style.height = max + 'px');
      }
    });
  };
  
  window.addEventListener('resize', calc);
  calc();
};

function fileName(str) {
  return str.split(/(\\|\/)/g).pop();
}

function loadImage(src) {
  return new Promise((resolve, reject)=> {
    let img = new Image();
    
    img.onload = () => {
      resolve({
        img,
        src: img.src,
        width: img.width,
        height: img.height
      });
    };
    
    img.onerror = () => {
      reject('That image was not found');
    };
    
    img.src = src;
  });
}

// Restricts input for all elements in the given jQuery object according to the given inputFilter.
function setInputFilter(obj, inputFilter) {
  obj.on('input keydown keyup mousedown mouseup select contextmenu', function() {
    filterInput(this);
  });
  obj.each(function(index, element) {
    element.inputFilter = inputFilter;
    filterInput(element);
  });
}

// Implements input filtering for the given textbox.
function filterInput(textbox) {
  if (!textbox.hasOwnProperty('oldValue') || textbox.inputFilter(textbox.value)) {
    textbox.oldValue = textbox.value;
    textbox.oldSelectionStart = textbox.selectionStart;
    textbox.oldSelectionEnd = textbox.selectionEnd;
  } else {
    textbox.value = textbox.oldValue;
    textbox.setSelectionRange(textbox.oldSelectionStart, textbox.oldSelectionEnd);
  }
}

function makeid() {
  let text = '';
  let possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  for (let i = 0; i < 5; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }

  return text;
}

function loadScript(src) {
  return new Promise(function (resolve, reject) {
    let s;
    s = document.createElement('script');
    s.src = src;
    s.onload = resolve;
    s.onerror = reject;
    document.head.appendChild(s);
  });
}

export {
  equalHeight,
  setState,
  getState,
  defaults,
  q,
  fileName,
  loadImage,
  isIE,
  setInputFilter,
  makeid,
  loadScript
};

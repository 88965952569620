import $ from 'jquery';
window.$ = window.jQuery = $;
import 'jquery-ui/ui/widgets/datepicker.js';
import fileUploadImagePreview from './file-upload-image-preview';
import {getState} from './defaults';
import {setInputFilter, loadScript } from './utility';
import ejs from 'ejs/ejs';
import 'jquery-ui/ui/widgets/autocomplete.js';

function form() {
  let state = getState();

  function labels() {
    function check(x) {
      let $target = x.target ? $(x.target) : $(x);
      let $label = $target.parents('label');
      if (
        $label.length &&
        (
          $target.val().trim() ||
          $target.is('-webkit-autofill')
        )
      ) {
        $label.addClass('filled');
      } else {
        $label.removeClass('filled');
      }

      if($target[0].disabled) {
        $label.addClass('disabled');
      } else {
        $label.removeClass('disabled');
      }
    }

    state.$body.on('input change', 'input,textarea', check);
    window.setTimeout(() => state.$body.on('input change', 'input,textarea', check),500);
    state.$body.find('input, textarea').each(function (i, el) {
      check(el);
    });
  }

  function filterContent() {
    state.$body.find('.zip-filter').each(function (i, v) {
      setInputFilter($(v), function(value) {
        return /^\d{0,4}$/.test(value);
      });
    });

    state.$body.find('.number-filter').each(function (i, v) {
      setInputFilter($(v), function(value) {
        return /^\d*$/.test(value);
      });
    });
  }

  function fileUploadValidation() {
    function calc() {
      let valid = false;

      $('.attachment-box').each(function (i, v) {
        if ($(v).find('[data-file-state="complete"]').length) {
          valid = true;
        } else {
          valid = false;
          return false;
        }
      });

      if (valid) {
        $('.next-step').attr('data-valid', 'true');
      } else {
        $('.next-step').attr('data-valid', 'false');
      }
    }

    calc();
  }

  function selects() {
    let selector = '.custom-select select';

    function check(x) {
      let $t = x.target ? $(x.target) : $(x);
      let $customSelect = $t.parents('.custom-select');
      let text = $t.find(' option:selected').text();
      $customSelect.find('.mask').text(text);
      if ($t.val() !== '') {
        $customSelect.addClass('filled');
      } else {
        $customSelect.removeClass('filled');
      }

      if($t.length && $t[0].disabled) {
        $customSelect.addClass('disabled');
      } else {
        $customSelect.removeClass('disabled');
      }

      $customSelect.attr('data-value', $t.val());
    }

    state.$body.on('change', selector, check);
    state.$body.find(selector).each(function (i, el) {
      check(el);
    });

    window.setTimeout(() => state.$body.find(selector).each(function (i, el) {
      check(el);
    }),500);
  }

  function datepicker() {
    $.datepicker.regional['hu'] = {
      closeText: 'bezár',
      prevText: 'vissza',
      nextText: 'előre',
      currentText: 'ma',
      monthNames: ['Január', 'Február', 'Március', 'Április', 'Május', 'Június', 'Július', 'Augusztus', 'Szeptember', 'Október', 'November', 'December'],
      monthNamesShort: ['Jan', 'Feb', 'Már', 'Ápr', 'Máj', 'Jún', 'Júl', 'Aug', 'Szep', 'Okt', 'Nov', 'Dec'],
      dayNames: ['Vasárnap', 'Hétfő', 'Kedd', 'Szerda', 'Csütörtök', 'Péntek', 'Szombat'],
      dayNamesShort: ['Vas', 'Hét', 'Ked', 'Sze', 'Csü', 'Pén', 'Szo'],
      dayNamesMin: ['V', 'H', 'K', 'Sze', 'Cs', 'P', 'Szo'],
      weekHeader: 'Hét',
      dateFormat: 'yy.mm.dd',
      firstDay: 1,
      isRTL: false,
      showMonthAfterYear: true,
      yearSuffix: ''
    };
    $.datepicker.setDefaults($.datepicker.regional['hu']);
    $('.datepicker').each(function (i, v) {
      $(v).datepicker({
        onSelect: function(selectedDate) {
          $(v).trigger('input');
          $(v).trigger('blur');
        }
      });
    });
  }

  function validateRules() {
    $.validator.addMethod('regex', function (value, element, regex) {
      let rule = new RegExp(regex);
      return this.optional(element) || rule.test(value);
    });

    $.validator.addMethod('time', function (value, element) {
      return this.optional(element) || /^\d{1,2}:\d{1,2}$/.test(value);
    });

    $.validator.addMethod('number', function (value, element) {
      return this.optional(element) || /^\d+$/.test(value);
    });

    $.validator.addMethod('custompassword', function (value, element) {
      return this.optional(element) || /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{7,}$/.test(value);
    });
  };

  function validate() {
    // https://johnnycode.com/2014/03/27/using-jquery-validate-plugin-html5-data-attribute-rules/
    // https://jqueryvalidation.org/validate/
    $('form:not(.jquery-validate)').each(function (i, form) {
      let $form = $(form);
      $form.validate({
        errorClass: 'is-invalid',
        validClass: 'is-valid',
        highlight(element, errorClass, validClass) {
          window.setTimeout(function () {
            $(element).parents('.form-group').addClass(errorClass).removeClass(validClass);
          }, 1);
        },
        unhighlight(element, errorClass, validClass) {
          window.setTimeout(function () {
            let $parent = $(element).parents('.form-group');
            $parent.removeClass(errorClass).addClass(validClass);
            $parent.find('.form-error').html('');
          }, 1);
        },
        errorPlacement(error, element) {
          //console.log(error);
          $(element[0]).parents('.form-group').find('.form-error').html(`<p>${error[0].innerHTML}</p>`);
        },
        /* showErrors: function(errorMap, errorList) {
          console.log({errorMap, errorList});
        } */
      });
      $form.addClass('jquery-validate');
    });
  }

  function setSubmitButton($form) {
    if ($form.length) {
      if ($form.validate().checkForm()) {
        $form.attr('data-form-valid', 'true');
        $form.find('[type=submit], .next-step').prop('disabled', false);
      } else {
        $form.attr('data-form-valid', 'false');
        $form.find('[type=submit], .next-step').prop('disabled', true);
      }
    }
  }

  function validationCheck() {
    let selector = 'input, textarea, select';
    function check(e) {
      let $form = e.target ? $(e.target).parents('form') : $(e);
      setSubmitButton($form);
    }

    state.$body.on('blur keyup change input', selector, check);
    $('form').each((i, x) => check(x));
  }

  function hierarchySelect() {
    $('[data-hierarchy-select-target]').each(function (i, select) {
      let $select = $(select);
      $select.change(function (e) {
        let target = $('[name=' + $select.data('hierarchy-select-target') + ']');
        let values = $select.find(':selected').data('values');
        target.empty();
        target.append('<option value=""></option>');

        if (values && values.length > 0) {
          values.forEach(function (p) {
            target.append(`<option value="${p.oid}">${p.Nev}</option>`);
          });
        }

        target.change();
      });

    });
  }

  function multiSelect() {
    $('[data-multiselect]').each(function (i, button) {
      let $button = $(button);
      let selector = $button.data('multiselect');

      $(selector).last().addClass('last-group');

      $button.click(function (e) {
        let groups = $(selector);

        if ($button.data('max-groups') && groups.length >= $button.data('max-groups')) {
          return;
        }

        let clone = groups.last().clone(true);

        clone.addClass('last-group');
        groups.removeClass('last-group');

        groups.last().after(clone);

        // reset select
        clone.find('select').val('').trigger('change');

        // reset radio
        clone.find('[data-radio]').prop('checked', false);

        // update index numbers
        $(selector).each(function (i, group) {
          $(group).find('[name]').each(function (j, input) {
            $(input).attr('name', $(input).attr('name').replace(/\[\d+\]/, `[${i}]`));
          });
        });

      });
      $(document).on('click', selector + ' .close', {}, function (e) {
        if ($(selector).length > 1) {
          $(e.target).parents(selector).remove();
        }
      });
    });
  }

  function szakemberSelect() {
    $('[data-szakember-select]').each(function (i, selectContainer) {
      $(selectContainer).find('select').parent().on('change', 'select', function (e) {
        const profilkep = $(e.target).find(':selected').data('profilkep');
        let p = $(e.target).parent();

        while (p.find('.avatar').length < 1) {
          p = p.parent();
        }

        p.find('.avatar')
          .css('background-image', `url(${profilkep})`);
      });
    });
  }

  function updateInputs(main, sub) {
    $(main).find(sub).each((i, box) => {
      $(box).find('input, textarea, select').each((j, v) => {
        $(v).attr('name', $(v).attr('name').replace(/\[(\d{1,4})\]/, `[${i}]`));
      });
    });
  }

  function expertSkills() {
    $('.expert-skill-add').on('click', function (e) {
      let $skillList = $('.skill-list');
      let template = $('#expert-skill-item').html()
        .replace('<span class="expert-skill-number">1</span>', `<span class="expert-skill-number">${$skillList.find('.skill').length + 1}</span>`);

      $skillList.append(template);
      updateInputs('.skill-list', '.attachment-box');
      setSubmitButton($(e.target).parents('form'));
    });

    state.$body.on('click', '.skill-list .skill .cross', function (e) {
      let $form = $(e.target).parents('form');
      $(this).parents('.skill').remove();
      $('.skill-list .skill').each(function (i, x) {
        $(x).find('.expert-skill-number').html( i + 1);
      });
      updateInputs('.skill-list', '.attachment-box');
      setSubmitButton($form);
    });
  }

  function dataServer() {
    $('form[data-server]').each(function (i, form) {
      let server = $(form).data('server');

      if (server && server.action && server.action.type) {
        if (server.action.type == 'open-layer') {
          $('body').trigger('toggle', [server.action.id]);
        }
      }

      if (server && server.errors) {
        serverErrors(form, server);
      }
    });
  }

  function serverErrors(form, server) {
    Object.entries(server.errors).forEach((x) => {
      let [name, error] = x;
      let $el = $(`[name="${name}"]`, form);
      let $parent;

      if ($el.length){
        $parent = $el.parents('.form-group');
        $parent.addClass('is-invalid');
        $parent.find('.form-error').html(`<p>${error}</p>`);
      }
    });
  }

  function disableInputs() {
    let selector = '[data-disable-input]';

    function check(e) {
      let $source = e.target ? $(e.target) : $(e);
      let val = $source.attr('data-disable-input');
      let $target = val ? $(`[data-disable-input-target="${val}"]`) : $source.parents('.form-category');
      let checked = $source[0].checked;
      let $collection = $target.find('input, textarea, select').filter((i, x) => x !== $source[0]);

      $collection.each(function (i, x) {
        let $t = $(x);
        $t[0].disabled = checked ? true : false;
        $t.trigger('change');
      });
    }

    state.$body.on('input change', selector, check);
    state.$body.find(selector).each(function (i, el) {
      check(el);
    });
  }

  function fakeValidate() {
    $('form a.fake-validate').on('click', function (e) {
      let validator = $(this).parents('form').validate();
      if (!validator.form()) {
        e.preventDefault();
      }
    });
  }

  function charCounter() {
    function calc(i, counter) {
      let $textarea = $(counter).parents('.form-group').find('textarea');
      let maxLength = $textarea.attr('data-maxlength') * 1;

      if (maxLength) {
        setInterval(() => {
          counter.innerHTML = `${$textarea.val().length} / ${maxLength}`;
          /* if ($textarea.val().length > maxLength) {
            $textarea.val($textarea.val().slice(0, maxLength));
          } */
        }, 500);
      }
    }

    $('.char-counter').each(calc);
  }

  function ajaxForm() {
    $('[data-ajax-form]').each(function (i, form) {
      const $form = $(form);

      $form.submit(function (e) {
        e.preventDefault();

        $.post($form.attr('action'), $form.serialize(), function (data) {
          console.log(data);

          if (data.status == 'ERR') {
            serverErrors($form, data);
          } else {
            $form.find('.message-placeholder').text(data.message);
          }
        });
      });
    });
  }

  function submitsForm() {
    $('[data-submits-form]').each(function (i, element) {
      const $element = $(element);
      const event = $element.data('submits-form');
      const form = $element.closest('form');

      $element.on(event, function () {
        form.submit();
      });
    });
  }

  function ajaxFileUpload() {
    const fileTemplate = $('#file-template');
    const templateString = fileTemplate.html();
    let groups = {};
    let xhrs = [];

    $('[data-file-group]').each(function (i, group) {
      const $group = $(group);
      const tipusKod = $group.data('file-group');
      const files = fileTemplate.data('files');

      groups[tipusKod] = $group;

      if (tipusKod in files) {
        files[tipusKod].forEach((f) => {
          $group.append(ejs.render(templateString, f, {delimiter: '?'}));
        });
      }
    });

    $('[data-ajax-file-upload]').each(function (i, form) {
      $(form).submit(function (e) {
        e.preventDefault();

        const formData = new FormData(form);

        formData.getAll('file').forEach(file => {
          console.log(file);

          if (file.name) {
            const xhr = new XMLHttpRequest();
            const f = {
              FileName: file.name
            };
            const fileFormData = new FormData(form);
            fileFormData.set('file', file);

            const uploadDiv = $(ejs.render(templateString, f, {delimiter: '?'}));

            groups[fileFormData.get('tipusKod')].append(uploadDiv);
            uploadDiv.find('[data-file-cancel]').addClass('d-inline').click(function () {
              xhr.abort();
              uploadDiv.remove();
            });

            xhr.open('post', $(form).attr('action'), true);

            xhr.setRequestHeader('Accept', 'application/json');

            xhr.upload.onprogress = function(e) {
              if (e.lengthComputable) {
                const percentage = (e.loaded / e.total) * 100;
                uploadDiv.find('.line').css({ width: percentage + '%'});
              }
            };

            xhr.onerror = function(e) {
              console.log('Error');
              console.log(e);
            };
            xhr.onload = function() {
              try {
                if (this.status == 200) {
                  const created = JSON.parse(this.response);
                  const fileDiv = $(ejs.render(templateString, created, {delimiter: '?'}));
                  uploadDiv.remove();
                  groups[fileFormData.get('tipusKod')].append(fileDiv);
                  fileUploadValidation();
                } else {
                  const e = JSON.parse(this.response);
                  const fileNameEl = uploadDiv.find('.file-name');

                  fileNameEl.text(fileNameEl.text() + ' - ' + e.error);
                  fileNameEl.addClass('error');
                  uploadDiv.attr('data-file-state', 'failed');
                }
              } catch (e) {
                console.error(e);
              }
            };

            xhr.send(fileFormData);
            xhrs.push(xhr);
          }
        });
      });
    });

    window.addEventListener('beforeunload', function (event) {
      if (xhrs.find((xhr) => xhr.readyState < 4)) {
        // Cancel the event as stated by the standard.
        event.preventDefault();
        // Chrome requires returnValue to be set.
        event.returnValue = '';
      }
    });
  }

  function customRadio() {
    $(document).on('change', '[data-radio]', {}, function (e) {
      const selected = $(e.target);
      const others = $(`[data-radio="${selected.data('radio')}"]`);

      others.not(selected).prop('checked', false);
      selected.prop('checked', true);
    });
  }

  function zipCodeAutoComplete() {
    $('[data-zipcode]').each(function (i, zipInput) {
      const $zipInput = $(zipInput);

      $zipInput.autocomplete({
        source: '/api/zipcode',
        minLength: 1,
        select: function(event, ui) {
          // console.log(ui.item);
          $zipInput.val(ui.item.value);
          $zipInput.parents('.form-category').find('[data-city]').val(ui.item.telepules).trigger('change');
          $zipInput.parents('.form-category').find('[data-city-oid]').val(ui.item.telepulesOid).trigger('change');
        }
      });
    });
  }

  function helyszinAutoComplete() {
    $('[data-helyszin-nev]').each(function (i, nevInput) {
      const $nevInput = $(nevInput);
      const $form = $nevInput.parents('form');
      const helyszinFields = $form.find('[name*="Helyszin["]');

      helyszinFields.change(() => {
        $form.find('[name="HelyszinOid"]').val('');
      });

      $nevInput.autocomplete({
        source: '/api/helyszin',
        minLength: 3,
        select: function (event, ui) {
          $form.find('[name="Helyszin[Iranyitoszam][Kod]"]').val(ui.item.Iranyitoszam.Kod).trigger('change');
          $form.find('[name="Helyszin[Telepules][Nev]"]').val(ui.item.Telepules.Nev).trigger('change');
          $form.find('[name="Helyszin[Telepules][oid]"]').val(ui.item.Telepules.oid).trigger('change');
          $form.find('[name="Helyszin[KozteruletNeve]"]').val(ui.item.KozteruletNeve).trigger('change');
          $form.find('[name="Helyszin[KozteruletJellege][Nev]"]').val(ui.item.KozteruletJellege.Nev).trigger('change');
          $form.find('[name="Helyszin[Hazszam]"]').val(ui.item.Hazszam).trigger('change');
          $form.find('[name="Helyszin[Epulet]"]').val(ui.item.Epulet).trigger('change');
          $form.find('[name="Helyszin[Lepcsohaz]"]').val(ui.item.Lepcsohaz).trigger('change');
          $form.find('[name="Helyszin[Emelet]"]').val(ui.item.Emelet).trigger('change');
          $form.find('[name="Helyszin[Ajto]"]').val(ui.item.Ajto).trigger('change');

          $nevInput.blur();

          setTimeout(() => {
            $form.find('[name="HelyszinOid"]').val(ui.item.oid).trigger('change');
          }, 100);
        }
      });
    });
  }

  function googleCaptcha() {
    if ($('.g-recaptcha').length) {

      window.onCaptchaSubmit = function onCaptchaSubmit(token) {
        console.log('token', token);
        // document.getElementById("demo-form").submit();
        let $form = $('.g-recaptcha');
        $form.find('input[name="g-recaptcha-response"]').val(token);

        $form[0].submit();
      };

      window.onCaptchaLoadCallback = function onCaptchaLoadCallback() {
        window.grecaptcha.render('formSubmit', {
          'sitekey' : process.env.GOOGLE_CAPTCHA_SITE,
          'callback' : window.onCaptchaSubmit
        });
      };

      loadScript('https://www.google.com/recaptcha/api.js?onload=onCaptchaLoadCallback&render=explicit&hl=hu');
    }
  }

  disableInputs();
  labels();
  selects();
  datepicker();
  validateRules();
  hierarchySelect();
  multiSelect();
  szakemberSelect();
  dataServer();
  validate();
  fakeValidate();
  validationCheck();
  charCounter();
  fileUploadImagePreview();
  expertSkills();
  ajaxForm();
  ajaxFileUpload();
  submitsForm();
  customRadio();
  zipCodeAutoComplete();
  helyszinAutoComplete();
  filterContent();
  fileUploadValidation();
  googleCaptcha();
}

export default form;

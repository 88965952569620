import {getState} from './defaults';

export default function events () {
  let {layout, $body} = getState();

  function headerNavigation() {
    function notiPosition() {
      layout = getState().layout;
      $body = getState().$body;

      window.setTimeout(function () {
        if ($body.is('[data-overlay="noti"]')) {
          let $decor = $('[data-toggle-target=noti] .decor');
          let temp = $('button.noti-navigation')[0].getBoundingClientRect();
          $decor.css('left', temp.x + 7);
        }
      });
    }

    $body.on('click', '[data-prevent], [data-valid="false"]', function (e) {
      e.preventDefault();
    });

    $body.on('click', '.spec-menu', function (e) {
      $('body').trigger('toggle', [['lg', 'xl'].indexOf(layout) !== -1 ? 'sub-menu' : 'navigation']);
    });

    $body.on('click', '.noti-navigation', function (e) {
      if (['lg', 'xl'].indexOf(layout) !== -1) {
        $body.trigger('toggle', 'noti');
      } else {
        $body.trigger('toggle', 'navigation');
        $body.trigger('toggle', 'noti');
      }
    });

    $body.on('toggle-after', notiPosition);
    window.addEventListener('resize', notiPosition);
  }

  function profileChangeRequest() {
    $body.on('click', '.profile-change-request button', function () {
      const $t = $(this);
      $t.prop('disabled', true);

      // TODO ajax call to send profile change request
      $.ajax({
        url: `/${$t.hasClass('expert') ? 'szakember' : 'tagszervezet'}/profil/adatmodositas-igenyles`,
        success: function (res) {
          if (res.success) {
            $t.parent().find('.ajax-success').removeClass('d-none');
          } else {
            $t.parent().find('.ajax-error').removeClass('d-none');
          }
          // console.log('success', res);
        },
        error: function (err) {
          // console.log('error: ', err);
          $t.parent().find('.ajax-error').removeClass('d-none');
        },
        complete: function () {
          $t.addClass('d-none');
        }
      });
    });

    $body.on('toggle-after', function (e) {
      if (['member-data', 'expert-edit-profile'].indexOf(e.id) !== -1) {
        $('.profile-change-request button').removeClass('d-none').removeAttr('disabled');
        $('.profile-change-request p').addClass('d-none');
      }
    });
  }

  function eventDeleteRequest() {
    $body.on('click', '.event-delete-request', function () {
      const $t = $(this);
      $t.prop('disabled', true);

      $.ajax({
        type: 'POST',
        url: '/esemeny/torles',
        data: $t.parents('form').serialize(),
        success: function (res) {
          if (res.success) {
            $t.parent().find('.ajax-success').removeClass('d-none');
          } else {
            $t.parent().find('.ajax-error').removeClass('d-none');
          }
          // console.log('success', res);
        },
        error: function (err) {
          // console.log('error: ', err);
          $t.parent().find('.ajax-error').removeClass('d-none');
        },
        complete: function () {
          $t.addClass('d-none');
          $t.prop('disabled', false);
        }
      });
    });

    $body.on('toggle-after', function (e) {
      if (['member-data', 'expert-edit-profile'].indexOf(e.id) !== -1) {
        $('.profile-change-request button').removeClass('d-none').removeAttr('disabled');
        $('.profile-change-request p').addClass('d-none');
      }
    });
  }

  function notification() {
    $body.on('click', '.mark-all-read', function (e) {
      e.preventDefault();

      $.post('/notification/mark-all-read', {
        _csrf: $(this).attr('data-csrf')
      }, function () {
        console.log('mark-all-read');

        $body.find('.noti-row:not([data-read])').each((i,n) => $(n).attr('data-read', true));
      });
    });
    $body.on('click', '.noti-row:not([data-prevent])', function () {
      let link = $(this).find('.link').html();
      let xhr = null;

      if (link) {
        xhr = $.post(`/notification/${$(this).attr('data-id')}`, {
          _csrf: $(this).attr('data-csrf')
        });

        xhr.onreadystatechange = function() {
          console.log(xhr.readyState);
        };

        xhr
          .done(function() {
            console.log('noti success');
          })
          .fail(function() {
            console.log('noti error');
          });


        window.setTimeout(function () {
          window.location.href = link;
        },1000);
      }
    });
  }

  headerNavigation();
  profileChangeRequest();
  eventDeleteRequest();
  notification();
}

import 'simplebar';
import {defaults, isIE } from './utility';

import overlay from './overlay';
import equalHeightGroups from './equal-height-group';
import form from './form';
import toggle from './toggle';
import xScroll from './x-scroll';
import sticky from './sticky';
import decor from './decor';
import parallax from './parallax';
import events from './events';
import cookieDisclaimer from './cookie-disclaimer';

defaults().then(() => {
  isIE();
  overlay();
  equalHeightGroups();
  toggle();
  form();
  xScroll();
  sticky();
  decor();
  parallax();
  events();
  cookieDisclaimer();
  
  
  if(window.location.hash === '#noti'){
    $('body').trigger({
      type: 'toggle',
      action: 'open',
      ids: 'noti'
    });
  }
  
  if(window.location.hash === '#test123'){
    $('body').trigger({
      type: 'toggle',
      action: 'open',
      ids: 'thank-you'
    });
  }
  
  if(window.location.hash === '#test1234'){
    $('body').trigger({
      type: 'toggle',
      action: 'open',
      ids: 'member-data'
    });
  }
  
});

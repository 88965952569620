import { equalHeight, q } from './utility';

function equalHeightGroup() {
  let items = q('[data-equal-height-item]');
  let groups = {};
  
  items.forEach((x) => {
    let category = x.getAttribute('data-equal-height-item');
    if (!groups[category]) {
      groups[category] = [];
    }
    groups[category].push(x);
  });
  
  Object.entries(groups).forEach(x => {
    let [key] = x;
    let items = `[data-equal-height-item=${key}]`;
    equalHeight(items);
  });
}

export default equalHeightGroup;

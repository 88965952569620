import cookie from './cookie';
import {getState} from './defaults';

export default function cookieDisclaimer() {
  let {$body} = getState();
  
  if (cookie.getItem('cookieconsent_status') !== 'dismiss') {
    $body.trigger('toggle', 'cookie');
  }
  
  $body.on('toggle-after', function (e) {
    if (e.id === 'cookie') {
      cookie.setItem('cookieconsent_status', 'dismiss', Infinity);
    }
  });
}

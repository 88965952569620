import $ from 'jquery';

let state = {};

function getState() {
  return state;
};

function setState(update  = {}) {
  state = {...update, ...state};
  return state;
};

function setLayout() {
  function calc() {
    let x = window.innerWidth;
    
    switch (true) {
      case x >= 1200:
        return 'xl';
      case x >= 992:
        return 'lg';
      case x >= 768:
        return 'md';
      case x >= 576:
        return 'sm'; 
      default: {
        return 'xs';
      }
    }
  }
  
  state.layout = calc();
  window.addEventListener('resize', () => state.layout = calc());
}

function defaults() {
  return new Promise(function (resolve, reject) {
    $(() => {
      state = {...state, ...{
        $body: $('body'),
        $window: $(window)
      }};
      
      setLayout();
      resolve(state);
    });
  });
}

export {
  getState,
  setState
};

export default defaults;

import { q } from './utility';

function sticky() {
  function calc(el) {
    let placeHolder = q(el.parentElement, '.sticky-placeholder')[0];
    let data;
    
    function setData() {
      clear();
      // console.log('el', el);
      let x = el.getBoundingClientRect();
      let y = q('html')[0].getBoundingClientRect();
      // console.log(x.top);
      // console.log(y.top);
      data = {
        top: x.top - y.top,
        height: x.height,
        width: placeHolder.getBoundingClientRect().width
      };
      setFixed();
    }
    
    function clear() {
      el.classList.remove('fixed');
      placeHolder.style.height = 0;
      el.style.width = 'auto';
    }
    
    function setFixed() {
      //console.log('data.top', data.top);
      let doc = document.documentElement;
      let currentScrollTop = (window.pageYOffset || doc.scrollTop)  - (doc.clientTop || 0);
      let fixed = data.top <= currentScrollTop;
  
      if (fixed) {
        el.classList.add('fixed');
        placeHolder.style.height = data.height + 'px';
        el.style.width = data.width + 'px';
      } else {
        clear();
      }
    }
    
    window.addEventListener('resize', setData);
    window.addEventListener('scroll', setFixed);
    setData();
    setFixed();
  }
  
  
  q('.sticky').forEach(calc);
}

export default sticky;

import { getState } from './utility';

function overlay () {
  let { $body, $window } = getState();
  
  $body.on('click', '.overlay-bg', function () {
    $body.trigger({
      type: 'toggle',
      ids: $(this).parent().attr('data-toggle-target')
    });
  });
  
  $body.on('click', '.overlay', function (e) {
    let $target = $(e.target);
    if ($target.is('.row')){
      $body.trigger({
        type: 'toggle',
        action: 'close',
        ids: $target.parents('.overlay').attr('data-toggle-target')
      });
    }
  });
  
  $body.on('toggle-after', function (e) {
    let $overlay = $(`[data-toggle-target=${e.id}]`);
    let $content = $overlay.find('.overlay-content');
    
    if (!$overlay.length || !$overlay.hasClass('overlay')){
      return;
    }
    
    window.setTimeout(function () {
      if (e.action === 'open') {
        if ($content) {
          calcPosition($overlay, $content);
          $overlay.addClass('overlay-open');
        }
      } else {
        $(`[data-toggle-target=${e.id}]`).removeClass('overlay-open');
        $body.removeClass('overflow-hidden');
      }
      
      if ($content) {
        if (e.action === 'open') {
          $body.attr('data-overlay', e.id);
        } else {
          $body.removeAttr('data-overlay', e.id);
        }
      }
    });
  });
  
  window.addEventListener('resize', function () {
    let $overlay = $('.overlay.overlay-open');
    if ($overlay.length) {
      calcPosition ($overlay, $overlay.find('.overlay-content'));
    }
  });
  
  function calcPosition($overlay, $content) {
    if (!$overlay.hasClass('skip-position')) {
      if ($content.height() > window.innerHeight - 40) {
        $content.css('top', Math.floor($window.scrollTop() + 20));
        window.setTimeout(() => {
          $body.removeClass('overflow-hidden');
        });
      } else {
        $content.css('top',  Math.floor($window.scrollTop() +(window.innerHeight - $content.height()) / 2));
        window.setTimeout(() => {
          $body.addClass('overflow-hidden');
        });
      }
    } 
  }
  
  $body.find('.overlay').appendTo($body);
};

export default overlay;

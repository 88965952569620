import { loadImage } from './utility';

let config = {
  loaded: false,
  imagesAdded: false
};
let pos = {
  'kuzdosportag-login': [
    [75, 70],
    [50, 40],
    [37, 37],
    [17, 23]
  ],
  'technikai-login': [
    [65, 58],
    [50, 48],
    [37, 37],
    [43, 48]
  ],
  'loveszet-login': [
    [55, 60],
    [50, 60],
    [50, 50],
    [48, 43]
  ],
  'kadet-login': [
    [55, 58],
    [38, 45],
    [40, 40],
    [28, 23]
  ],
  'altalanos-login': [
    [55, 58],
    [38, 45],
    [40, 40],
    [28, 23]
  ],
  kuzdosportag: [
    [45, 48],
    [45, 50],
    [44, 44],
    [35, 38]
  ],
  technikai: [
    [50, 55],
    [45, 50],
    [47, 47],
    [50, 47]
  ],
  loveszet: [
    [55, 60],
    [50, 55],
    [50, 50],
    [50, 45]
  ],
  kadet: [
    [50, 60],
    [50, 55],
    [40, 40],
    [38, 35]
  ],
  altalanos: [
    [50, 60],
    [50, 55],
    [50, 50],
    [50, 45]
  ]
};

function isMobile() {
  return window.innerWidth < 992;
}

function mouseEvents() {
  window.addEventListener('mousemove', function (e) {
    let {clientY: y} = e;
    let percentage = Math.floor(y / window.innerHeight * 100) ;
    animate(percentage);
  });
};

function changeCss(imageIndex, percentage) {
  let [a, b] = pos[config.name + (config.position === 'login' ? '-login' : '')][imageIndex];
  let diff = (a - b);
  let result = Math.floor(a - diff * percentage / 100);
  //console.log({percentage, result});
  config.$images.eq(imageIndex).css({
    'transform': `translate(-50%,-${result}%)`
  });
}

function animate(percentage) {
  config.$images.each((i) => {
    changeCss(i, percentage);
  });
}

function appendImages() {
  config.$t.append(config.images.reduce((acc, img, i) => {
    return acc += `<img data-index=${++i} src="${img.src}">`;
  }, ''));
  config.$images = config.$t.find('img');
};

function loadImages() {
  return new Promise(function (resolve, reject) {
    if (config.mobile) {
      resolve();
      return;
    }
    Promise.all(
      [4, 3, 2, 1]
        .map(x => loadImage(`/static/img/parallax/${config.name}/${x}.png`))
    )
      .then(images => {
        config.images = images;
        resolve();
      })
      .catch(reject);
  });
};

function reposition() {
  if (config.position === 'login') {
    config.$t.appendTo('body');
  }
  console.log('config.name', config.name);
  config.$t.attr('data-parallax', config.name);
}

function checkWindowSize() {
  function calc() {
    if (config.position === 'login') {
      if (window.innerHeight > config.$images.eq(0).height()) {
        config.$t.addClass('reverse');
      } else {
        config.$t.removeClass('reverse');
      }
      if (!config.loaded) {
        config.$t.addClass('loaded');
        config.loaded = true;
      }
    } else {
      config.$t.addClass('loaded');
      config.loaded = true;
    }
  }
  
  window.addEventListener('resize', calc);
  calc();
}

function setConfig(v) {
  let $t = $(v);
  config = {
    ...config, 
    t: v,
    $t: $t,
    name: window.location.search ? window.location.search.split('=')[1] : $t.data('parallax'),
    position: $t.data('position'),
    mobile: isMobile()
  };
}

function loading() {
  function calc() {
    if (!config.imagesAdded) {
      config.mobile = isMobile();
      
      if (!config.mobile) {
        config.imagesAdded = true;
        
        loadImages()
          .then(() => {
            //animate();
            if(!config.mobile) {
              appendImages();
              checkWindowSize();
              mouseEvents();
              console.log('config', config);
            }
            
          })
          .catch(err =>  {
            console.log(err);
          });
      }
    }
  }
  
  window.addEventListener('resize', calc);
  calc();
}

function init(i, v) {
  setConfig(v);
  reposition();
  loading();
}

function parallax() {
  $('[data-parallax]').each(init);
}


export default parallax;

import $ from 'jquery';
window.$ = window.jQuery = $;
import {getState} from './defaults';

function decor() {
  let state = getState();
  let $decors = $('.decor-lines');
  
  function calc() {
    if (['lg', 'xl'].indexOf(state.layout) !== -1){
      let contentWidth =state.$body.is('.create') 
        ? $('.col-lg-8').eq(0).width()
        : $('.content').eq(0).width();
        
      //console.log(contentWidth);
      let temp = (window.innerWidth - contentWidth) / 2;
      
      $decors.eq(0).css({ width: temp + 100});
      $decors.eq(1).css({ width: temp + 100});
      $decors.eq(2).css({ width: temp + 100 });
      
      if (state.$body.height() < 2000) {
        $decors.eq(0).hide();
      } else {
        $decors.eq(0).show();
      }
      
      $decors.parent().addClass('loaded');
    }
  }
  
  window.addEventListener('resize', calc);
  calc();
}

export default decor;

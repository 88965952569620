import {getState, q} from './utility';

function setScroll(wrapper) {
  let item = q(wrapper, '.scroll > div')[0];
  let scroll = q(wrapper, '.scroll')[0];
  
  function calc() {
    if ('xs-sm-md-lg'.split('-').indexOf(getState().layout) !== -1) {
      let scrollLeft = Math.floor((item.getBoundingClientRect().width - scroll.getBoundingClientRect().width) / 2);
      scroll.scrollLeft = scrollLeft;
      wrapper.classList.add('inited');
    }
  }
  
  window.addEventListener('resize', calc);
  calc();
}

function xScroll() {
  let items = q('.scroll-wrapper');
  items.forEach(setScroll);
}

export {
  setScroll
};

export default xScroll;

import $ from 'jquery';
import {setState} from './defaults';

function toggle() {
  let state = setState({
    test: 1
  });

  function change(id) {
    let $target = $(`[data-toggle-target=${id}]`);
    let isOpen = $target.is('[data-open]');
    let action = isOpen ? 'close' : 'open';

    //before event
    state.$body.trigger({ type: 'toggle-before', action, id });

    if (isOpen) {
      $target.removeAttr('data-open');
    } else {
      $target.attr('data-open', '');
    }

    //after event
    state.$body.trigger({ type: 'toggle-after', action, id });
  }

  function search(e, id) {
    e.preventDefault();

    if (id) {
      var ids = [id];
    } else {
      var ids = (e.ids ? e.ids : $(this).attr('data-toggle')).split('|');
    }

    ids.forEach(change);
  }

  state.$body.on('click', '[data-toggle]', search);
  state.$body.on('toggle', search);
}

export default toggle;
